import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Typography,
  Button,
  Card,
  showErrorAlert,
  Spinner,
} from 'components/alf-design';
import './UserProfile.css';
import { withApi, useStorage, useResponsive, usePolicy, useAuth0 } from 'utils';
import { datadogLogs } from '@datadog/browser-logs';

import LinkedPolicies from './LinkedPolicies';
import LinkPolicy from './LinkPolicy';

const styles = {
  wrapper: {
    padding: 16,
    textAlign: 'center',
  },
  circularProgress: {
    display: 'flex',
    justifyContent: 'center',
  },
  title: {
    marginBottom: 8,
    textAlign: 'center',
  },
  subtitle: {
    marginTop: '48px',
    marginBottom: '16px',
  },
  email: {
    marginBottom: 32,
    textAlign: 'center',
    color: '#6648DC',
    cursor: 'pointer',
  },
  buttonReset: {
    minWidth: 244,
    alignSelf: 'center',
  },
  buttonLink: {
    minWidth: 244,
    margin: '24px',
  },
};
const UserProfile = ({ api, history }) => {
  const { user, getTokenSilently } = useAuth0();
  const { name } = user;
  const isAdmin = user
    ? user.email.includes('@slideinsurance.com') &&
      user.role &&
      user.role?.isAdmin
    : false;

  const { error, policies, loading, setLoading } = usePolicy(null, isAdmin, {
    loadPolicies: true,
  });
  const [, setPasswordChanged] = useStorage('passwordChanged', null);
  const { screenIsDown } = useResponsive();

  useEffect(() => {
    if (error) {
      setLoading(false);
      showErrorAlert({
        message: `${
          error.entity.charAt(0).toUpperCase() + error.entity.slice(1)
        } Error`,
        description: errorDescription(error.err, error.entity),
      });
    }
  }, [error, setLoading]);

  useEffect(() => {
    document.title = 'My Profile | Slide Insurance';
    if (window.location.search.includes('changePassword=true')) {
      goToReset();
    }
  });

  const goToReset = async () => {
    const sessionActive = !!(await getTokenSilently({
      // eslint-disable-next-line camelcase
      redirect_uri: `${window.location.origin}?changePassword=true`,
    }));

    if (sessionActive) {
      window.history.replaceState(
        window.history.state,
        document.title,
        '/user-profile',
      );
      setLoading(true);
      api.withAuth
        .post('/identity/change-password', {
          redirectUrl: window.origin,
        })
        .then((res) => {
          setPasswordChanged(true);
          window.location = res.data.url;
        })
        .catch((err) => {
          let description;
          if (err.response) {
            description = err.response.data.error;
          } else if (err.request) {
            description =
              'Network error. Please check your connection and try again.';
          } else {
            description = `Error attempting to change password.`;
          }
          datadogLogs.logger.error(`Change Password - ${user.email}`, {
            error,
          });
          showErrorAlert({
            message: 'Change Password Error:',
            description,
          });
          setLoading(false);
        });
    }
  };

  const errorDescription = (err, request) => {
    let description;
    if (err.response) {
      description = err.response.data.error;
    } else if (err.request) {
      description =
        'Network error. Please check your connection and try again.';
    } else {
      description = `Error attempting to fetch your ${request}. Refresh page to try again.`;
    }
    return description;
  };

  useEffect(() => {
    if (policies) {
      setLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [policies]);

  const [showLinkPolicy, toggleLinkPolicy] = useState(false);
  return !loading ? (
    <div style={{ display: 'flex', justifyContent: 'center' }}>
      <div className="user-profile">
        <Card>
          <div style={styles.wrapper}>
            <Typography style={styles.title} type="heading2" component="h1">
              {name}
            </Typography>
            <div style={{ display: 'inline-grid' }}>
              <Button
                id="reset-password"
                style={styles.buttonReset}
                onClick={goToReset}
                link
                disabled={isAdmin}
              >
                Reset Password
              </Button>
            </div>
          </div>
        </Card>
        {policies && policies.length > 0 && (
          <>
            <Typography type="heading3" component="h2" style={styles.subtitle}>
              My Policies
            </Typography>
            <Card style={{ padding: '16px' }}>
              <ul
                style={{
                  listStyleType: 'none',
                  margin: 'unset',
                  padding: 'unset',
                }}
              >
                <LinkedPolicies policies={policies} />
              </ul>
              <div style={{ textAlign: 'center' }}>
                <Button
                  type="primary"
                  style={{
                    ...styles.buttonLink,
                    padding: screenIsDown('sm') ? '10px 20px 0' : '8px 20px 0',
                  }}
                  id="link-policy-button"
                  onClick={() => toggleLinkPolicy(true)}
                  link
                  disabled={isAdmin}
                >
                  Add Existing Policy
                </Button>
              </div>
            </Card>
          </>
        )}
      </div>
      <LinkPolicy
        show={showLinkPolicy}
        onClose={() => toggleLinkPolicy(false)}
        api={api}
        history={history}
      />
    </div>
  ) : (
    <div style={styles.circularProgress}>
      <Spinner id="dashboard-spinner" size={80} />
    </div>
  );
};

UserProfile.propTypes = {
  api: PropTypes.object,
  history: PropTypes.object,
};

export default withApi(UserProfile);
